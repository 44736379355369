<template>
  <div>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="list">
          <div @click="onNavigator({path:'/teacher/salary/detail?id='+item.bill_id + '&name=' + item.salary_name})" v-for="(item,index) in list" :key="index" class="list-item">
            <van-cell style="border-bottom:1px solid #f8f8f8" :title="item.salary_month" is-link>
                <template #default>
                    <span>¥</span>
                    <span>{{item.salary_bill}}</span>
                </template>
            </van-cell>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'salary',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    //   this.onPage()
  },
  methods: {
    onListLoad() {
        this.onPage()
    },
    onPage() {
      this.$api.employee_salaryRoll({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>
<style lang="less" scoped>

    .container{
      padding: 10px 0;
    }
    .list-item{
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      background: #fff;
      &-media{
        margin-right: 10px;
        &-img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      &-title{
        font-size: 14px;
        color: #222;
        margin-bottom: 6px;
      }
      &-inner{
        flex: 1;
        width: 100%;
      }
    }
</style>

